import React from 'react';
import styled from '@emotion/styled';
import Label from '../components/Label';
import BlankSpace from '../components/blankSpace';
import convertHtml from '../utils/convertHtml';
import LazyImage from '../components/lazy-image';
import TextCard from '../components/textCard';
import TextCardNoTitle from '../components/textCardNoTitle';
import linkStripper from '../utils/link-stripper';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import ButtonNotUnderlinedReadMore from '../components/ButtonNotUnderlinedReadMore';

const LeftTextContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media (max-width: 500px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    display: none;
  }
`;

const LeftImageContainer = styled.div`
  height: 475px;
  width: 180px;
  @media (max-width: 1100px) {
    margin-right: 50px;
  }
`;

const MainContentContainerLeft = styled.div`
  margin-left: 75px;
  display: flex;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  @media (max-width: 1450px) {
    width: 50%;
  }

  @media (max-width: 1100px) {
    width: 100%;
    margin: 0;
    height: unset;
  }
`;

const MainContentContainerLeftInner = styled.div`
  height: fit-content;
  width: 100%;
  padding-right: 100px;
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 40px;
  color: #ffffff;
  line-height: 48px;
  max-width: 1000px;
  font-family: Montserrat;
  font-style: normal;
  @media (max-width: 1100px) {
    max-width: 90%;
  }
`;

const Paragraph = styled.div`
  line-height: 28px;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: #ffffff;
  max-width: 820px;
  @media (max-width: 900px) {
    top: 0;
    max-width: 100%;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
`;

const TextCardContainerOuterMaster = styled.div`
  width: 100%;
  @media (max-width: 1450px) {
    position: relative;
    width: 100vw;
    max-width: 100%;
    // margin-left: -255px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1100px) {
    // margin-left: -220px;
  }
  @media (max-width: 900px) {
    margin-left: 0px;
    width: 90vw;
  }
  @media (max-width: 500px) {
    width: 100vw;
  }
`;

const TextCardContainerOuter = styled.div`
  margin-top: 50px;
  max-width: 820px;
  @media (max-width: 1450px) {
    width: 90%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 375px) {
    display: none;
  }
`;

const LinkBlockContainer = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  @media (max-width: 900px) {
    margin-top: 35px;
  }
`;

const LinkItemContainer = styled.div`
  max-width: 380px;
  margin-right: 10px;
  margin-left: 10px;
  width: calc(50% - 20px);
  margin-bottom: 22.5px;
  padding-bottom: 22.5px;
  border-style: solid;
  border-color: #e5e5e5;
  border-bottom-width: 2px;
  :nth-last-child(1),
  :nth-last-child(2) {
    border-style: solid;
    border-color: #e5e5e5;
    border-bottom-width: 0px;
  }

  @media (max-width: 900px) {
    width: 100%;
    max-width: unset;
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 1190px) {
    :nth-last-child(2) {
      border-style: solid;
      border-color: #e5e5e5;
      border-bottom-width: 2px;
    }
  }
`;

const LinkItemContainerOdd = styled.div`
  width: 400px;
  margin-right: 20px;
  margin-bottom: 22.5px;
  padding-bottom: 22.5px;
  border-style: solid;
  border-color: #e5e5e5;
  border-bottom-width: 2px;
  :nth-last-child(1) {
    border-style: solid;
    border-color: #e5e5e5;
    border-bottom-width: 0px;
  }
`;

const LinkItem = styled.div`
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3ec8ba;
`;

const RightImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: -70px;
  margin-top: 180px;
  @media (max-width: 1450px) {
    margin-top: 40px;
  }
  @media (max-width: 1100px) {
    display: none;
  }
`;

const RightImageContainerInner = styled.div`
  width: 290px;
  height: 575px;
`;

const WhatWeDoOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const WhatWeDoInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 900px) {
    width: 90%;
  }
`;

const whatWeDo = ({
  props,
  label_text,
  label_color,
  title_text,
  paragraph_text,
  cta_card_subtitle,
  cta_card_title,
  cta_card_button_text,
  cta_card_button_color,
  left_aligned_image,
  right_aligned_image,
  link_block_color,
  link_block_item_target,
  cta_card_button_link,
  card_title_underline_color,
  link_block_item_target_target,
  link_block_item_read_more,
  link_block_item_color,
  cta_card_button_link_target,
  items,
}) => (
  <WhatWeDoOuterContainer>
    <WhatWeDoInnerContainer>
      <LeftTextContainer>
        <ImageContainer>
          <LeftImageContainer>
            {left_aligned_image && <LazyImage {...left_aligned_image} />}
          </LeftImageContainer>
        </ImageContainer>
        <MainContentContainerLeft>
          <MainContentContainerLeftInner>
            {label_text && (
              <>
                <LabelContainer>
                  <Label color={label_color} text={label_text} />
                </LabelContainer>
                <BlankSpace height="30px" />
              </>
            )}
            <Title>{title_text}</Title>
            <BlankSpace height="30px" />
            <Paragraph dangerouslySetInnerHTML={convertHtml(paragraph_text)} />
            <LinkBlockContainer>
              {items.map((item, index) => {
                if (items.length % 2 === 0) {
                  return (
                    <LinkItemContainer>
                      <LinkItem>
                        {item.link_block_item_read_more.text ? (
                          <ButtonNotUnderlinedReadMore
                            buttonText={item.link_block_item.text}
                            color={
                              item.link_block_item_color
                                ? '#ffffff'
                                : link_block_color
                            }
                            arrowColor={
                              item.link_block_item_color
                                ? item.link_block_item_color
                                : link_block_color
                            }
                            link={item.link_block_item_target.url}
                            target={item.link_block_item_target.target}
                            readmore={item.link_block_item_read_more.text}
                            fontSize={'16px'}
                          />
                        ) : (
                          <ButtonNotUnderlined
                            buttonText={item.link_block_item.text}
                            color={
                              item.link_block_item_color
                                ? '#ffffff'
                                : link_block_color
                            }
                            arrowColor={
                              item.link_block_item_color
                                ? item.link_block_item_color
                                : link_block_color
                            }
                            link={item.link_block_item_target.url}
                            target={item.link_block_item_target.target}
                            fontSize={'16px'}
                          />
                        )}
                      </LinkItem>
                    </LinkItemContainer>
                  );
                } else {
                  return (
                    <LinkItemContainerOdd>
                      <LinkItem>
                        {item.link_block_item_read_more.text ? (
                          <ButtonNotUnderlinedReadMore
                            buttonText={item.link_block_item.text}
                            color={
                              item.link_block_item_color
                                ? '#ffffff'
                                : link_block_color
                            }
                            arrowColor={
                              item.link_block_item_color
                                ? item.link_block_item_color
                                : link_block_color
                            }
                            link={item.link_block_item_target.url}
                            target={item.link_block_item_target.target}
                            readmore={item.link_block_item_read_more.text}
                            fontSize={'16px'}
                          />
                        ) : (
                          <ButtonNotUnderlined
                            buttonText={item.link_block_item.text}
                            color={
                              item.link_block_item_color
                                ? '#ffffff'
                                : link_block_color
                            }
                            arrowColor={
                              item.link_block_item_color
                                ? item.link_block_item_color
                                : link_block_color
                            }
                            link={item.link_block_item_target.url}
                            target={item.link_block_item_target.target}
                            fontSize={'16px'}
                          />
                        )}
                      </LinkItem>
                    </LinkItemContainerOdd>
                  );
                }
              })}
            </LinkBlockContainer>
            <TextCardContainerOuterMaster>
              <TextCardContainerOuter>
                {cta_card_title ? (
                  <TextCard
                    cta_card_subtitle={cta_card_subtitle}
                    cta_card_title={cta_card_title}
                    cta_card_button_text={cta_card_button_text.text}
                    cta_card_button_link={linkStripper(cta_card_button_link)}
                    cta_card_button_colour={cta_card_button_color}
                    cta_title_underline_color={card_title_underline_color}
                    cta_card_button_link_target={cta_card_button_link_target}
                  />
                ) : (
                  <TextCardNoTitle
                    cta_card_subtitle={cta_card_subtitle}
                    cta_card_title={cta_card_title}
                    cta_card_button_text={cta_card_button_text.text}
                    cta_card_button_link={linkStripper(cta_card_button_link)}
                    cta_card_button_colour={cta_card_button_color}
                    cta_title_underline_color={card_title_underline_color}
                    cta_card_button_link_target={cta_card_button_link_target}
                  />
                )}
              </TextCardContainerOuter>
            </TextCardContainerOuterMaster>
          </MainContentContainerLeftInner>
        </MainContentContainerLeft>
        <RightImageContainer>
          <RightImageContainerInner>
            {right_aligned_image && <LazyImage {...right_aligned_image} />}
          </RightImageContainerInner>
        </RightImageContainer>
      </LeftTextContainer>
    </WhatWeDoInnerContainer>
  </WhatWeDoOuterContainer>
);

export default whatWeDo;
